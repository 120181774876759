<template>
    <div class="rules-editor">
        <div class="rules-editor-items" v-for="(rule, ruleIndex) in rulesRef" :key="rule">
            <TextFieldEnh
                id="rulevalue"
                name="rulevalue"
                class="rules-editor-rule"
                label=""
                disabled
                :modelValue="rule.name"
            ></TextFieldEnh>
            <button class="rules-editor-remove-rule" @click.stop="removeRule(ruleIndex)">
                <img class="inline-block icon-compact" src="/assets/icons/delete.svg" />
            </button>
            <button class="rules-editor-edit-rule" @click.stop="editRule(ruleIndex)">
                <img class="inline-block icon-compact" src="/assets/icons/edit.svg" />
            </button>
        </div>
        <button class="rules-editor-add-rule" @click.stop="addRule">
            <img class="inline-block icon-compact" src="/assets/icons/circle-add.svg" />
            <span> Aggiungi regola</span>
        </button>
    </div>
    <ModalEditRule
        v-if="showAddRuleModal"
        @close="() => (showAddRuleModal = false)"
        :widget="widget"
        :rulesAlreadySet="rulesAlreadySet"
        :ruleIndex="currentRule"
        :rules="rulesRef"
    ></ModalEditRule>
</template>

<script setup>
import { ref } from 'vue';
import { useVModel } from '@vueuse/core';

import ModalEditRule from '../../modals/ModalEditRule.vue';
import TextFieldEnh from '../../lib/TextFieldEnh.vue';

const props = defineProps({
    widget: {
        type: Object,
        required: true,
    },
    // ogni opzione e' una stringa
    rules: {
        type: Array,
        required: true,
    },
    rulesAlreadySet: {
        type: Object,
        required: true,
    },
});
const emit = defineEmits(['update:rules']);

const rulesRef = useVModel(props, 'rules', emit);

const showAddRuleModal = ref(false);
const currentRule = ref(false);
const addRule = () => {
    currentRule.value = false;
    showAddRuleModal.value = true;
};
const editRule = (index) => {
    currentRule.value = index;
    showAddRuleModal.value = true;
};
const removeRule = (index) => {
    rulesRef.value.splice(index, 1);
};
</script>

<style lang="postcss" scoped>
.rules-editor {
    @apply flex flex-col;
}
.rules-editor-add-rule {
    @apply py-3 px-3 flex items-center justify-center gap-1;
    @apply bg-stone-100;
    @apply text-sm font-semibold;
}
.rules-editor-items {
    @apply flex flex-row gap-2;
}
.rules-editor-remove-rule {
    @apply border rounded border-solid border-green-light-active;
    @apply mb-4;
    @apply h-12 w-12 shrink-0;
}
.rules-editor-edit-rule {
    @apply border rounded border-solid border-green-light-active;
    @apply mb-4;
    @apply h-12 w-12 shrink-0;
}
</style>
