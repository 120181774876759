<template>
    <div ref="formWidgetRef" class="widget-combobox">
        <div class="widget-combobox-item">
            <label
                v-if="widgetRef.instanceProperties.label"
                class="field-label"
                :class="{ 'pointer-event-none': mode == 'edit' }"
                :for="widgetRef.instanceProperties.id"
                >{{ widgetRef.instanceProperties.label }}
                <span v-if="widgetRef.validationProperties.required || RulesService.isRequired(widget)">*</span></label
            >
            <div class="flex flex-row">
                <DropdownList
                    :id="widgetRef.instanceProperties.id"
                    :name="widgetRef.instanceProperties.name"
                    v-model:value="widgetRef.runtimeProperties.data"
                    class="combobox-root"
                    :class="{ 'pointer-events-none': mode == 'edit' }"
                    :multiple="widgetRef.instanceProperties.multiple"
                    :options="widgetRef.instanceProperties.options"
                    :disabled="disabled"
                ></DropdownList>
            </div>
        </div>
        <slot></slot>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import { useVModel } from '@vueuse/core';

import DropdownList from '../../lib/DropdownList.vue';
import RulesService from '../../../services/RulesService';
// import { useLoggerStore } from '../../../stores/loggerStore';

const props = defineProps({
    mode: {
        type: String,
        default: 'view',
    },
    widget: {
        type: Object,
        required: true,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
});
const emit = defineEmits(['update:widget']);

// const { logStatuses } = useLoggerStore();

const widgetRef = useVModel(props, 'widget', emit, { passive: true, deep: true });
const formWidgetRef = ref();

if (widgetRef.value.runtimeProperties.data == null) {
    if (widgetRef.value.runtimeProperties.multiple) {
        widgetRef.value.runtimeProperties.data = [];
    } else {
        widgetRef.value.runtimeProperties.data = null;
    }
}
</script>

<style lang="postcss" scoped>
.widget-combobox {
    @apply border-0  w-full;
}
</style>
