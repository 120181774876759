<template>
    <div ref="formWidgetRef" class="widget-range">
        <div class="widget-range-item relative">
            <label
                v-if="widgetRef.instanceProperties.label"
                class="field-label"
                :class="{ 'pointer-event-none': mode == 'edit' }"
                :for="widgetRef.instanceProperties.id"
                >{{ widgetRef.instanceProperties.label }}
                <span v-if="widgetRef.validationProperties.required || RulesService.isRequired(widget)">*</span></label
            >
            <div class="w-full h-5"></div>
            <div class="flex flex-row">
                <span class="font-nubesans font-normal mr-4">{{ widgetRef.instanceProperties.min }}</span>
                <SliderRoot
                    v-model:modelValue="widgetRef.runtimeProperties.data"
                    class="slider-root"
                    :class="{ 'pointer-events-none': mode == 'edit', disabled: disabled }"
                    :max="
                        Number(
                            _.isNumber(widgetRef.instanceProperties.max)
                                ? widgetRef.instanceProperties.max
                                : _.isString(widgetRef.instanceProperties.max)
                                ? widgetRef.instanceProperties.max.replace(',', '.')
                                : 'error'
                        )
                    "
                    :min="
                        Number(
                            _.isNumber(widgetRef.instanceProperties.min)
                                ? widgetRef.instanceProperties.min
                                : _.isString(widgetRef.instanceProperties.min)
                                ? widgetRef.instanceProperties.min.replace(',', '.')
                                : 'error'
                        )
                    "
                    :step="
                        Number(
                            _.isNumber(widgetRef.instanceProperties.step)
                                ? widgetRef.instanceProperties.step
                                : _.isString(widgetRef.instanceProperties.step)
                                ? widgetRef.instanceProperties.step.replace(',', '.')
                                : 'error'
                        )
                    "
                    :disabled="disabled"
                >
                    <SliderTrack class="slider-track">
                        <SliderRange class="slider-range" />
                    </SliderTrack>
                    <SliderThumb class="slider-thumb" aria-label="Volume">
                        <div class="absolute -top-[2rem]">{{ widgetRef.runtimeProperties.data[0] }}</div>
                    </SliderThumb>
                </SliderRoot>
                <span class="font-nubesans font-normal ml-4">{{ widgetRef.instanceProperties.max }}</span>
            </div>
        </div>
        <slot></slot>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import { useVModel } from '@vueuse/core';

import { SliderRange, SliderRoot, SliderThumb, SliderTrack } from 'radix-vue';
import RulesService from '../../../services/RulesService';
import _ from 'lodash';
// import { useLoggerStore } from '../../../stores/loggerStore';
const props = defineProps({
    mode: {
        type: String,
        default: 'view',
    },
    widget: {
        type: Object,
        required: true,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
});
console.log('WIDGET RANGE PROPS: ', props);

const emit = defineEmits(['update:widget']);

// const { logStatuses } = useLoggerStore();

const widgetRef = useVModel(props, 'widget', emit, { passive: true, deep: true });
const formWidgetRef = ref();

if (widgetRef.value.runtimeProperties.data == null) {
    widgetRef.value.runtimeProperties.data = [widgetRef.value.instanceProperties.min];
}
</script>

<style lang="postcss" scoped>
.widget-range {
    @apply border-0 h-full w-full;
}
:deep(.slider-root) {
    @apply relative flex items-center select-none touch-none grow h-5;
}
.slider-track {
    @apply bg-stone-300 relative grow rounded-full h-2;
}
:deep(.slider-root.disabled) .slider-track {
    @apply bg-stone-200;
}
.slider-range {
    @apply absolute bg-green-normal rounded-full h-full;
}
:deep(.slider-root.disabled) .slider-range {
    @apply bg-stone-400;
}
.slider-thumb {
    @apply flex justify-center w-5 h-5 bg-white shadow-[0_2px_5px] border border-solid border-green-normal rounded-[10px] hover:bg-green-normal-light focus:outline-none focus:shadow-[0_0_0_5px] focus:shadow-green-normal;
}
:deep(.slider-root.disabled) .slider-thumb {
    @apply border-stone-400 hover:bg-white focus:border-stone-400;
}

.widget-range-item {
    @apply flex flex-col gap-2;
}
</style>
