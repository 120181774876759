<template>
    <div ref="formWidgetRef" class="widget-checkbox-group">
        <div v-if="widgetRef.instanceProperties.label" class="w-full field-label mb-2">
            <span>{{ widgetRef.instanceProperties.label }}</span>
            <span v-if="widgetRef.validationProperties.required || RulesService.isRequired(widget)">*</span>
        </div>
        <div v-for="option in widgetRef.instanceProperties.options" :key="option" class="flex items-center mb-2">
            <div class="widget-checkbox-group-item">
                <input
                    type="checkbox"
                    :id="normalizedName(option)"
                    :name="normalizedName(option)"
                    :value="option.label"
                    v-model="widgetRef.runtimeProperties.data"
                    class="mr-2"
                    :class="{ 'pointer-event-none': mode == 'edit' }"
                    :disabled="disabled"
                />
                <label :class="{ 'pointer-event-none': mode == 'edit' }" :for="normalizedName(option)">{{ option.label }}</label>
            </div>
        </div>

        <slot></slot>
    </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import { useVModel } from '@vueuse/core';
import slugify from 'slugify';
import RulesService from '../../../services/RulesService';

// import { useLoggerStore } from '../../../stores/loggerStore';

const props = defineProps({
    mode: {
        type: String,
        default: 'view',
    },
    widget: {
        type: Object,
        required: true,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
});
const emit = defineEmits(['update:widget']);

// const { logStatuses } = useLoggerStore();

const widgetRef = useVModel(props, 'widget', emit, { passive: true, deep: true });
const formWidgetRef = ref();

onMounted(() => {
    if (widgetRef.value.runtimeProperties.data == null) {
        widgetRef.value.runtimeProperties.data = [];
    }
});

const normalizedName = computed(() => {
    return (option) => {
        return widgetRef.value.instanceProperties.id + '-' + slugify(option.label);
    };
});
</script>

<style lang="postcss" scoped>
.widget-checkbox-group {
    @apply border-0 w-full;
}
.widget-checkbox-group-item {
    @apply flex items-center;
}
</style>
