<template>
    <div ref="formWidgetRef" class="widget-input-text">
        <div class="widget-input-text-item">
            <label
                v-if="widgetRef.instanceProperties.label"
                class="field-label"
                :class="{ 'pointer-event-none': mode == 'edit' }"
                :for="widgetRef.instanceProperties.id"
                >{{ widgetRef.instanceProperties.label }}
                <span v-if="widgetRef.validationProperties.required || RulesService.isRequired(widget)">*</span>
            </label>
            <div class="flex flex-row">
                <TextFieldEnh
                    :id="widgetRef.instanceProperties.id"
                    :name="widgetRef.instanceProperties.name"
                    label=""
                    :placeholder="widgetRef.instanceProperties.placeholder"
                    v-model:modelValue="widgetRef.runtimeProperties.data"
                    :mode="mode"
                    :class="{ 'pointer-events-none': mode == 'edit' }"
                    :disabled="disabled"
                ></TextFieldEnh>
            </div>
        </div>
        <slot></slot>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import { useVModel } from '@vueuse/core';

import TextFieldEnh from '../../lib/TextFieldEnh.vue';
import RulesService from '../../../services/RulesService';

const props = defineProps({
    mode: {
        type: String,
        default: 'view',
    },
    widget: {
        type: Object,
        required: true,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
});
const emit = defineEmits(['update:widget']);

const widgetRef = useVModel(props, 'widget', emit, { passive: true, deep: true });

const formWidgetRef = ref();
</script>

<style lang="postcss" scoped>
.widget-input-text {
    /* @apply h-full; */
}
:deep(.field-input-container--disabled) {
    @apply bg-stone-100 border-neutral-normal-light;
}
</style>
