<template>
    <div ref="formWidgetRef" class="widget-checkbox">
        <div v-if="widgetRef.instanceProperties.label" class="w-full field-label mb-2">
            <span>{{ widgetRef.instanceProperties.label }}</span>
            <span v-if="widgetRef.validationProperties.required || RulesService.isRequired(widget)">*</span>
        </div>
        <!-- <div class="flex items-center mb-2"> -->
        <div class="widget-checkbox-item">
            <input
                type="checkbox"
                :id="normalizedName(widgetRef.instanceProperties.option)"
                :value="slugify(widgetRef.instanceProperties.option.label)"
                v-model="widgetRef.runtimeProperties.data"
                class="mr-2"
                :class="{ 'pointer-events-none': mode == 'edit' }"
            />
            <label :class="{ 'pointer-events-none': mode == 'edit' }" :for="normalizedName(widgetRef.instanceProperties.option)">{{
                widgetRef.instanceProperties.option.label
            }}</label>
        </div>
        <!-- </div> -->

        <slot></slot>
    </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useVModel } from '@vueuse/core';
import slugify from 'slugify';
import RulesService from '../../../services/RulesService';

// import { useLoggerStore } from '../../../stores/loggerStore';

const props = defineProps({
    mode: {
        type: String,
        default: 'view',
    },
    widget: {
        type: Object,
        required: true,
    },
});
const emit = defineEmits(['update:widget']);

// const { logStatuses } = useLoggerStore();

const widgetRef = useVModel(props, 'widget', emit, { passive: true, deep: true });
const formWidgetRef = ref();

if (widgetRef.value.runtimeProperties.data == null) {
    widgetRef.value.runtimeProperties.data = false;
}
const normalizedName = computed(() => {
    return (option) => {
        return widgetRef.value.instanceProperties.id + '-' + slugify(option.label);
    };
});
</script>

<style lang="postcss" scoped>
.widget-checkbox {
    @apply border-0 h-full w-full;
}
.widget-checkbox-item {
    @apply flex items-center;
}
</style>
