<template>
    <div ref="formWidgetRef" class="widget-button-group" :class="{ disabled: disabled }" :disabled="disabled">
        <div v-if="widgetRef.instanceProperties.label" class="w-full field-label mb-2">
            <span>{{ widgetRef.instanceProperties.label }}</span>
            <span v-if="widgetRef.validationProperties.required || RulesService.isRequired(widget)">*</span>
        </div>
        <div class="widget-button-group-actions">
            <div
                v-for="option in widgetRef.instanceProperties.options"
                :key="option"
                class="flex items-center widget-button-group-action"
                :disabled="disabled"
            >
                <input
                    type="radio"
                    :id="normalizedName(option)"
                    :value="slugify(option.label)"
                    v-model="widgetRef.runtimeProperties.data"
                    class="widget-button-group-state mr-2 hidden"
                />
                <!-- :class="{ 'pointer-event-none': mode == 'edit' }" -->
                <label :for="normalizedName(option)" :class="{ 'pointer-events-none': mode == 'edit' || disabled }">
                    <div class="widget-button-group-button" :class="{ 'pointer-events-none': mode == 'edit' || disabled }">
                        {{ option.label }}
                    </div>
                </label>
            </div>
        </div>
        <slot></slot>
    </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useVModel } from '@vueuse/core';
import slugify from 'slugify';
import RulesService from '../../../services/RulesService';

// import { useLoggerStore } from '../../../stores/loggerStore';

const props = defineProps({
    mode: {
        type: String,
        default: 'view',
    },
    widget: {
        type: Object,
        required: true,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
});
const emit = defineEmits(['update:widget']);

// const { logStatuses } = useLoggerStore();

const widgetRef = useVModel(props, 'widget', emit, { passive: true, deep: true });
const formWidgetRef = ref();

const normalizedName = computed(() => {
    return (option) => {
        return widgetRef.value.instanceProperties.id + '-' + slugify(option.label);
    };
});
</script>

<style lang="postcss" scoped>
.widget-button-group {
    @apply border-0  w-full;
    @apply block;

    .widget-button-group-button {
        @apply px-4 py-2;
        @apply bg-white border-y-2 border-l-2 border-stone-200 border-solid rounded;
    }
    .widget-button-group-actions {
        @apply flex items-center justify-start;
    }
    div.widget-button-group-action:last-child {
        @apply border-r-2 rounded;
    }
    .widget-button-group-state:checked ~ label > .widget-button-group-button {
        /* @apply bg-stone-200; */
        @apply text-white;
        @apply bg-green-dark;
    }

    &.disabled {
        .widget-button-group-button {
            @apply bg-stone-100;
        }
        .widget-button-group-state:checked ~ label > .widget-button-group-button {
            @apply text-stone-800;
            @apply bg-stone-200;
        }
    }
}
</style>
