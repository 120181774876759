<template>
    <div ref="formWidgetRef" class="widget-image">
        <div class="widget-image-item">
            <label
                v-if="widgetRef.instanceProperties.label"
                class="field-label"
                :class="{ 'pointer-event-none': mode == 'edit' }"
                :for="widgetRef.instanceProperties.id"
                >{{ widgetRef.instanceProperties.label }}
                <span v-if="widgetRef.validationProperties.required || RulesService.isRequired(widget)">*</span></label
            >
            <div class="block">
                <UploadField
                    accept="image/jpg, image/jpeg, image/png"
                    v-model:uploadedFile="widgetRef.runtimeProperties.data"
                    class="widget-image-uploader"
                    :class="{ 'pointer-events-none': mode == 'edit' }"
                    :disabled="disabled"
                >
                    <!-- property file-preview di UploadField ="mettere data uri o url immagine pubblica" -->
                    <div v-if="isFile" class="widget-image-preview alfa">
                        <img
                            :src="preview"
                            :style="{
                                'max-width': widgetRef.instanceProperties.w + 'mm',
                                'max-height': widgetRef.instanceProperties.h + 'mm',
                            }"
                        />
                        <div class="widget-actions">
                            <img src="/assets/icons/delete.svg" class="icon-normal" @click="removeImage($event)" />
                        </div>
                    </div>
                    <div v-else-if="isString" class="widget-image-preview alfa">
                        <img
                            :src="widgetRef.runtimeProperties.data"
                            :style="{
                                'max-width': widgetRef.instanceProperties.w + 'mm',
                                'max-height': widgetRef.instanceProperties.h + 'mm ',
                            }"
                        />
                        <div class="widget-actions">
                            <img src="/assets/icons/delete.svg" class="icon-normal" @click="removeImage()" />
                        </div>
                    </div>
                    <div v-else class="widget-image-browse gamma">
                        <div class="widget-image-placeholder">
                            <div class="flex justify-center items-center p-4 border border-solid border-neutral-normal-light">
                                <img src="/assets/icons/image-upload.svg" class="icon-large" />
                            </div>
                            <div class="flex flex-col items-center">
                                <p><span class="text-green-normal underline">Seleziona l'immagine</span> da caricare.</p>
                                <p>PNG o JPG (max 5mb?)</p>
                            </div>
                        </div>
                    </div>
                </UploadField>
            </div>
        </div>
        <slot></slot>
    </div>
</template>

<script setup>
import { computed, onBeforeUpdate, onMounted, ref } from 'vue';
import { useVModel } from '@vueuse/core';

// import { useLoggerStore } from '../../../stores/loggerStore';

import UploadField from '../../lib/UploadField.vue';
import RulesService from '../../../services/RulesService';

const props = defineProps({
    mode: {
        type: String,
        default: 'view',
    },
    widget: {
        type: Object,
        required: true,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
});
const emit = defineEmits(['update:widget']);

const widgetRef = useVModel(props, 'widget', emit, { passive: true, deep: true });
const preview = ref();
const formWidgetRef = ref();

onBeforeUpdate(() => {
    console.log('UPDATE');
    if (isFile.value) {
        const reader = new FileReader();
        reader.onload = () => {
            preview.value = reader.result;
        };
        reader.onerror = (error) => {
            console.error(`Error reading file ${widgetRef.value.runtimeProperties.data.name}: ${error}`);
        };
        reader.readAsDataURL(widgetRef.value.runtimeProperties.data);
    }
});
onMounted(() => {
    console.log('MOUNTED');
    if (isFile.value) {
        const reader = new FileReader();
        reader.onload = () => {
            preview.value = reader.result;
        };
        reader.onerror = (error) => {
            console.error(`Error reading file ${widgetRef.value.runtimeProperties.data.name}: ${error}`);
        };
        reader.readAsDataURL(widgetRef.value.runtimeProperties.data);
    }
});
const isFile = computed(() => {
    return widgetRef.value.runtimeProperties.data instanceof File;
});
const isString = computed(() => {
    return typeof widgetRef.value.runtimeProperties.data == 'string';
});
const removeImage = (event) => {
    widgetRef.value.runtimeProperties.data = null;
    event.stopPropagation();
};
</script>

<style lang="postcss" scoped>
.widget-image {
    @apply border-0  w-full;
}
.widget-image-uploader {
    @apply w-full justify-center;
    @apply border border-solid border-neutral-normal-light p-5;
    @apply relative;

    &[disabled='true'] {
        pointer-events: none;
        @apply bg-stone-100;
    }
    .widget-actions {
        pointer-events: none;
        @apply absolute top-0 right-0;
    }
}
.widget-image-browse {
    @apply p-1;
}
.widget-image-placeholder {
    @apply flex flex-col gap-4 items-center text-stone-400;

    img {
        @apply text-stone-400;
    }
}
.widget-image-item {
    @apply flex flex-col;
}
.widget-image-wrapper {
}
</style>
